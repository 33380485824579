import React from "react"
import { Container } from "react-bootstrap"
import { personalStyle } from "./styles/hr.module.css"

const Hr = () => (
  <Container>
    <hr className={personalStyle} />
  </Container>
)

export default Hr
