import React from "react"
import "../styles/faq.css"
import yamlData from "../content/faq/faq.yaml"
import Layout from "../components/common/layout"
import Hr from "../components/common/hr"
import { Col, Container, Row } from "react-bootstrap"
import Seo from "../components/common/seo"

const Faq = () => (
  <Layout>
    <Seo
      title="Mettre sa voiture à la casse, formalités, réponses et conseils."
      description="Vous souhaitez mettre votre véhicule à la casse, retrouvez toutes les démarches, conseil et infos pratiques sur notre FAQ"
    />
    <section style={{ textAlign: "justify" }}>
      <Hr />
      <Container>
        <Row>
          <Col className="padding-faq">
            <h1 className="principal-title-faq">{yamlData.principalTitle}</h1>

            <article className="animated fadeInUp delay-0.2s">
              <h2 className="title-faq">{yamlData.obligation.subTitle}</h2>
              <p className="paragraphe">{yamlData.obligation.paragraphe}</p>
            </article>

            <article className="animated fadeInUp delay-0.2s">
              <h2 className="title-faq">{yamlData.cout.subTitle}</h2>
              <p className="paragraphe">{yamlData.cout.paragraphe}</p>
            </article>

            <article className="animated fadeInUp delay-0.2s">
              <h2 className="title-faq">{yamlData.document.subTitle}</h2>
              <p className="paragraphe">
                {yamlData.document.subParagraphe1}
                <a
                  href={
                    "https://siv.interieur.gouv.fr/map-usg-ui/do/accueil_certificat"
                  }
                  className="link-faq"
                >
                  {" "}
                  {yamlData.document.link}{" "}
                </a>
                {yamlData.document.subParagraphe2}
              </p>
              <p className="paragraphe">{yamlData.document.paragraphe2}</p>
              <p className="paragraphe">{yamlData.document.paragraphe3}</p>
              <p className="paragraphe">{yamlData.document.paragraphe4}</p>
            </article>

            <article className="animated fadeInUp delay-0.2s">
              <h2 className="title-faq">{yamlData.assurer.title}</h2>
              <p className="paragraphe">
                {yamlData.assurer.paraghraphe}{" "}
                <a href={"tel:+33749387047"} className="link-faq">
                  {yamlData.assurer.link}
                </a>
                .
              </p>
            </article>

            <article className="animated fadeInUp delay-0.2s">
              <h2 className="title-faq">{yamlData.problem.title}</h2>
              <h3 className="sub-title-faq">{yamlData.problem.subTitle1}</h3>
              <p className="paragraphe">{yamlData.problem.paragraphe1}</p>

              <h3 className="sub-title-faq">{yamlData.problem.subTitle2}</h3>
              <p className="paragraphe">{yamlData.problem.paragraphe2}</p>

              <h3 className="sub-title-faq">{yamlData.problem.subTitle3}</h3>
              <p className="paragraphe">{yamlData.problem.paragraphe3}</p>

              <h3 className="sub-title-faq">{yamlData.problem.subTitle4}</h3>
              <p className="paragraphe">{yamlData.problem.paragraphe4}</p>
            </article>

            <section>
              <article className="animated fadeInUp delay-0.2s">
                <h2 className="title-faq">{yamlData.casse.title}</h2>
                <p className="paragraphe">{yamlData.casse.paragraphe1}</p>
                <p className="paragraphe">{yamlData.casse.paragraphe2}</p>

                <h3 className="sub-title-faq">{yamlData.casse.subTitle1}</h3>
                <p className="paragraphe">{yamlData.casse.paragraphe3}</p>
                <p className="paragraphe">{yamlData.casse.paragraphe4}</p>

                <h3 className="sub-title-faq">{yamlData.casse.subTitle2}</h3>
                <p className="paragraphe">{yamlData.casse.paragraphe5}</p>
                <p className="paragraphe">{yamlData.casse.paragraphe6}</p>

                <h3 className="sub-title-faq">{yamlData.casse.subTitle3}</h3>
                <p className="paragraphe">{yamlData.casse.paragraphe7}</p>
                <p className="paragraphe">{yamlData.casse.paragraphe8}</p>

                <h3 className="sub-title-faq">{yamlData.casse.subTitle4}</h3>
                <p className="paragraphe">{yamlData.casse.paragraphe9}</p>
                <p className="paragraphe">{yamlData.casse.paragraphe10}</p>

                <h3 className="sub-title-faq">{yamlData.casse.subTitle5}</h3>
                <p className="paragraphe">{yamlData.casse.paragraphe11}</p>
                <p className="paragraphe">{yamlData.casse.paragraphe12}</p>
                <p className="paragraphe">{yamlData.casse.paragraphe13}</p>
              </article>

              <article className="animated fadeInUp delay-0.2s">
                <div>
                  <h2 className="title-faq">{yamlData.reglementation.title}</h2>
                  <p className="paragraphe">
                    {yamlData.reglementation.paragraphe1}
                  </p>
                  <p className="paragraphe">
                    {yamlData.reglementation.paragraphe2}
                  </p>
                  <p className="paragraphe">
                    {yamlData.reglementation.paragraphe3}
                  </p>
                  <p className="paragraphe">
                    {yamlData.reglementation.paragraphe4}
                  </p>

                  <h3 className="sub-title-faq">
                    {yamlData.reglementation.subTitle}
                  </h3>
                  <p className="paragraphe">
                    {yamlData.reglementation.paragraphe5}
                  </p>
                  <p className="paragraphe">
                    {yamlData.reglementation.paragraphe6}
                  </p>
                  <p className="paragraphe">
                    {yamlData.reglementation.paragraphe7}
                  </p>
                  <p className="paragraphe">
                    {yamlData.reglementation.paragraphe8}
                  </p>
                  <p className="paragraphe">
                    {yamlData.reglementation.paragraphe9}
                  </p>
                  <p className="paragraphe">
                    {yamlData.reglementation.paragraphe10}
                  </p>
                  <p className="paragraphe">
                    {yamlData.reglementation.paragraphe11}
                  </p>
                  <p className="paragraphe">
                    {yamlData.reglementation.paragraphe12}
                  </p>
                </div>
              </article>
            </section>
          </Col>
        </Row>
      </Container>
    </section>
  </Layout>
)

export default Faq
